<template>
  <main-layout>
    <content class="d-block bg-light" id="user-page">
      <header
        class="text-center d-flex justify-content-center flex-column header-sub-pages"
      >
        <img
          v-if="user.Data.UserProfileImage"
          class="mx-auto header-img fallback-image"
          :src="`data:image/png;base64, ${user.Data.UserProfileImage}`"
          :alt="user.Data.FullName | getInitialsFilter"
        />
        <i
          v-else
          class="mx-auto header-img fallback-image"
          :alt="user.Data.FullName | getInitialsFilter"
          >{{ user.Data.FullName | getInitialsFilter }}</i
        >

        <h1 style="color: #fff">{{ user.Data.FullName }}</h1>
        <span></span>
        <hr />
        <span class="company">{{ user.Data.CompanyName }}</span>
      </header>
      <main class="container full">
        <div class="row py-5">
          <div class="col-12 col-lg-4 px-0 px-sm-3 pl-xl-0">
            <ul id="user-page-nav" class="bg-white Raleway-Medium p-0">
              <li
                style="cursor: pointer"
                id="user-profile-button"
                :class="{ active: accountData == 'Account Info' }"
              >
                <a @click="accountData = 'Account Info'">Profil Bilgileri</a>
              </li>
              <li
                style="cursor: pointer"
                id="user-notify-button"
                :class="{ active: accountData == 'Settings' }"
              >
                <a @click="accountData = 'Settings'">Ayarlar</a>
              </li>
            </ul>
          </div>
          <section id="profile-section" class="col-12 col-lg-8 px-0 px-sm-3 pr-xl-0">
            <!--Profil Bilgileri-->
            <div
              v-if="accountData == 'Account Info'"
              id="user-profile-div"
              class="Raleway-Regular user-page-section bg-white"
            >
              <h2 class="text-white px-3 px-sm-5 text-center">Profil Bilgileri</h2>
              <div class="px-3 px-sm-5 py-3 py-sm-4">
                <h3 class="Raleway-ExtraBold"></h3>
                <p class="font-italic py-3">
                  <label><b class="mr-2">Ad Soyad: </b></label>
                  <label> {{ user.Data.FullName }}</label
                  ><br />
                  <label><b class="mr-2">Şirket: </b></label>
                  <label> {{ user.Data.CompanyName }}</label
                  ><br />
                  <label><b class="mr-2">Bölüm:</b></label> <label></label><br />
                  <label><b class="mr-2">Bölge:</b></label> <label></label><br />
                  <label><b class="mr-2">Görev:</b></label> <label></label><br />
                  <label><b class="mr-2">E-Posta:</b></label>
                  <label>{{ user.Data.UserName }}</label
                  ><br />
                  <label><b class="mr-2">Telefon Numarası:</b></label> <label></label
                  ><br />
                </p>
              </div>
            </div>
            <!--Ayarlar-->
            <div
              v-if="accountData == 'Settings'"
              id="user-notify-div"
              class="Raleway-Regular user-page-section bg-white"
            >
              <h2 class="text-white px-3 px-sm-5 text-center">Ayarlar</h2>
              <div class="px-3 px-sm-5 py-3 py-sm-4">
                <h3 class="Raleway-ExtraBold pb-3">Bildirim Ayarları</h3>
                <form>
                  <div class="d-flex align-items-center mb-3">
                    <span>Girdiğim fikire beğeni geldiğinde bana bildir.</span>
                    <label
                      class="switch ml-auto mb-0 flex-shrink-0"
                      style="white-space: nowrap"
                    >
                      <input id="isNotifyLike" type="checkbox" />
                      <span class="slider round"></span>
                      <span>AÇIK</span>
                      <span>KAPALI</span>
                    </label>
                  </div>
                </form>
                <form>
                  <div class="d-flex align-items-center mb-3">
                    <span>Girdiğim fikire yorum geldiğinde bana bildir.</span>
                    <label
                      class="switch ml-auto mb-0 flex-shrink-0"
                      style="white-space: nowrap"
                    >
                      <input id="isNotifyComment" type="checkbox" />
                      <span class="slider round"></span>
                      <span>AÇIK</span>
                      <span>KAPALI</span>
                    </label>
                  </div>
                </form>
                <form>
                  <div class="d-flex align-items-center mb-3">
                    <span>Girdiğim fikire sponsor olunduğunda bana bildir.</span>
                    <label
                      class="switch ml-auto mb-0 flex-shrink-0"
                      style="white-space: nowrap"
                    >
                      <input id="isNotifySponsor" type="checkbox" />
                      <span class="slider round"></span>
                      <span>AÇIK</span>
                      <span>KAPALI</span>
                    </label>
                  </div>
                </form>
                <form>
                  <div class="d-flex align-items-center mb-3">
                    <span
                      >Girdiğim fikir öneri değerlendiricisine yönlendirildiğinde bana
                      bildir.</span
                    >
                    <label
                      class="switch ml-auto mb-0 flex-shrink-0"
                      style="white-space: nowrap"
                    >
                      <input id="isNotifyOrientEstimator" type="checkbox" />
                      <span class="slider round"></span>
                      <span>AÇIK</span>
                      <span>KAPALI</span>
                    </label>
                  </div>
                </form>
                <form>
                  <div class="d-flex align-items-center mb-3">
                    <span>Girdiğim fikir yönlendirildiğinde bana bildir.</span>
                    <label
                      class="switch ml-auto mb-0 flex-shrink-0"
                      style="white-space: nowrap"
                    >
                      <input id="isNotifyOrientSuggestion" type="checkbox" />
                      <span class="slider round"></span>
                      <span>AÇIK</span>
                      <span>KAPALI</span>
                    </label>
                  </div>
                </form>
                <form>
                  <div class="d-flex align-items-center mb-3">
                    <span>Girdiğim fikir ret edildiğinde bana bildir.</span>
                    <label
                      class="switch ml-auto mb-0 flex-shrink-0"
                      style="white-space: nowrap"
                    >
                      <input id="isNotifyCancel" type="checkbox" />
                      <span class="slider round"></span>
                      <span>AÇIK</span>
                      <span>KAPALI</span>
                    </label>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </main>
    </content>
  </main-layout>
</template>

<script>
import { mapGetters } from "vuex";
import { suggestionStatus, getInitials, dateDiff } from "../../helpers";
import MainLayout from "../../layouts/MainLayout.vue";
export default {
  name: "AccountInfo",
  components: {
    MainLayout,
  },
  data() {
    return {
      accountData: "Account Info",
    };
  },
  filters: {
    suggestionStatusFilter(value) {
      return suggestionStatus(value);
    },
    getInitialsFilter(value) {
      return getInitials(value);
    },
    dateDiffFilter(value) {
      return dateDiff(value);
    },
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
  },
};
</script>

<style></style>
